import React, { FunctionComponent, useMemo } from 'react';

import footer from 'components/lexemes/footer';
import interfaceLanguages from 'lib/languages.json';

import Select from 'components/select/select';

type Props = {
    lang: string;
    onChange?: (lang: string) => void;
};

const LanguageSelector: FunctionComponent<Props> = ({ lang, onChange }) => {
    const languagesList = useMemo(() => {
        return interfaceLanguages.map(({ name, code }) => ({
            title: name,
            value: code,
            href: `/${code}`,
        }));
    }, []);

    return (
        <Select
            onChange={onChange}
            defaultValue={lang}
            options={languagesList}
            hasMaxHeight={true}
            a11y={{
                label: footer.lxLanguageSelection(),
            }}
        />
    );
};

export default LanguageSelector;
