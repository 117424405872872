import * as React from 'react';

import { common } from 'components/lexemes';
import footer from 'components/lexemes/footer';
import LocaleContext from 'lib/locale-context';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';
import AppLinks from 'components/app-links/app-links';
import Button from 'components/button/button';
import LanguageSelector from 'components/language-selector/language-selector';
import SocialLinks, { SocialLinksItem } from 'components/social-links/social-links';

export type PrimaryNavigationItem = {
    title: string;
    url?: string;
    target?: string;
    onClick?: () => void;
    isHidden?: boolean;
};

export type SecondaryNavigationItem = {
    title: string;
    url?: string;
    target?: string;
    onClick?: (event: any) => void;
} | null;

type Props = {
    onSignInClick?: () => void;
    onAppleStoreClick?: () => void;
    onGoogleStoreClick?: () => void;
    onLanguageChange?: (lang: string) => void;
    primaryNavigation: PrimaryNavigationItem[];
    secondaryNavigation: SecondaryNavigationItem[];
    socialLinks: SocialLinksItem[];
    signInAction?: {
        href?: string;
        text: string;
    };
};

const Footer: React.FC<Props> = ({
    onAppleStoreClick,
    onGoogleStoreClick,
    onLanguageChange,
    primaryNavigation,
    secondaryNavigation,
    socialLinks,
    signInAction,
    onSignInClick,
}) => {
    const lang = React.useContext(LocaleContext);

    return (
        <footer id="footer" className="page-footer">
            <div className="page-footer__inner">
                <div className="page-footer__row">
                    <div className="page-footer__actions">
                        <LanguageSelector lang={lang} onChange={onLanguageChange} />

                        {signInAction ? (
                            <Button
                                text={signInAction.text}
                                onClick={onSignInClick}
                                href={signInAction.href}
                            />
                        ) : null}
                    </div>

                    <AppLinks
                        onAppleStoreClick={onAppleStoreClick}
                        onGoogleStoreClick={onGoogleStoreClick}
                        description={footer.lxDownloadCopyText()}
                    />
                </div>
                <div className="page-footer__row">
                    <nav className="page-footer__navigation" aria-label={footer.lxA11yFooter()}>
                        <div className="page-footer__nav page-footer__nav--primary">
                            <ul className="page-footer__nav-list" role="list">
                                {primaryNavigation.map((link, index) =>
                                    link.isHidden ? undefined : (
                                        <li className="page-footer__nav-item" key={index}>
                                            {link.url ? (
                                                <a
                                                    className="page-footer__nav-link"
                                                    href={link.url}
                                                    onClick={link.onClick}
                                                    target={link.target}
                                                >
                                                    {link.title}

                                                    {link.target === '_blank' ? (
                                                        <A11yVisuallyHidden>
                                                            {common.lxA11yOpensInNewWindow}
                                                        </A11yVisuallyHidden>
                                                    ) : null}
                                                </a>
                                            ) : (
                                                <button
                                                    className="page-footer__nav-link"
                                                    onClick={link.onClick}
                                                >
                                                    {link.title}
                                                </button>
                                            )}
                                        </li>
                                    ),
                                )}
                            </ul>
                        </div>

                        <div className="page-footer__nav page-footer__nav--secondary">
                            <ul className="page-footer__nav-list" role="list">
                                {secondaryNavigation.map((link, index) => {
                                    if (!link) {
                                        return undefined;
                                    }
                                    return (
                                        <li className="page-footer__nav-item" key={index}>
                                            {link.url ? (
                                                <a
                                                    className="page-footer__nav-link"
                                                    href={link.url}
                                                    onClick={link.onClick}
                                                    target={link.target}
                                                >
                                                    {link.title}

                                                    {link.target === '_blank' ? (
                                                        <A11yVisuallyHidden>
                                                            {common.lxA11yOpensInNewWindow}
                                                        </A11yVisuallyHidden>
                                                    ) : null}
                                                </a>
                                            ) : (
                                                <button
                                                    className="page-footer__nav-link"
                                                    onClick={link.onClick}
                                                >
                                                    {link.title}
                                                </button>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </nav>

                    <div className="page-footer__additions">
                        <div className="page-footer__social-links">
                            <SocialLinks items={socialLinks} ariaLabel={footer.lxFollowUsTitle()} />
                        </div>

                        <p className="page-footer__block copyright page-footer__copy">
                            {footer.lxCopyright()}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
