import React from 'react';

import supportedLanguageIds, { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

function processHref(href = '', language?: string | null): string {
    let processedHref = href;

    // trim trailing slash for non-root urls
    if (processedHref.length > 1 && processedHref.endsWith('/')) {
        processedHref = processedHref.substr(0, processedHref.length - 1);
    }

    if (!processedHref.startsWith('http')) {
        // If link doesn't start with http, log warning and convert to local link
        if (!(processedHref.startsWith('/') || processedHref.slice(0, 1) === '#')) {
            console.warn('Invalid link. Must be a local or valid http link', processedHref);
            processedHref = `/${processedHref}`;
        }

        // 1. If it's a local link
        // 2. There is a language in the url
        // 3. There is no language in the link
        // Then add it
        if (language && !startsWithSupportedLanguage(processedHref)) {
            processedHref = `/${language}${processedHref}`;
        }
    }

    return processedHref;
}

function LinkWithRouter(
    props: React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    >,
) {
    const router = useRouter();
    const language = getLanguageFromUrl(router ? router.asPath : '');
    const href = processHref(props.href, language);

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} href={href} />;
}

function LinkStub(
    props: React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    >,
) {
    const language = getLanguageFromUrl('/');
    const href = processHref(props.href, language);

    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} href={href} />;
}

function Link(
    props: React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    >,
) {
    if (__STYLEGUIDE__) {
        return <LinkStub {...props} />;
    }

    return <LinkWithRouter {...props} />;
}

Link.props = {
    href: PropTypes.string,
    'data-href': PropTypes.string,
    rel: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    classname: PropTypes.string,
};

export default Link;

export function getLanguageFromUrl(urlStr: string) {
    // Filter out empty and dynamic routes
    const split = urlStr
        .split('/')
        .filter((path) => path !== '')
        .filter((path) => !/\[.+?\]/.test(path));

    // extract language
    const splitPart = split.shift();
    if (splitPart && supportedLanguageIds.includes(splitPart as SupportedLanguage)) {
        return splitPart as SupportedLanguage;
    }

    return null;
}

function startsWithSupportedLanguage(url?: string) {
    if (!url) {
        return false;
    }

    return supportedLanguageIds.some((language) => url.startsWith(`/${language}/`));
}
